.lead {
  background-color: #F4F4F4;
  padding: 60px 0;
  @include m(767) {
    padding: 30px 0;
  }

  .list_item {
    @include m(959) {
      margin-top: 12px;
    }
    @include m(459) {
      margin-top: 9px;
    }
  }
  &_card {
    border-radius: 20px;
    padding: 40px 26px 40px;

    @include m(959) {
      padding: 45px 18px 30px;
    }
    @include m(767) {
      position: relative;
      overflow: hidden;
      padding: 30px 12px 20px;
    }
    @include m(479) {
      padding: 25px 15px;
    }
    &::before {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
    }
  }
  &_col {
    position: relative;
    z-index: 2;
  }
  &_subtitle {
    margin-top: 15px;
  }
  &_price {
    font-size: 36px;
    line-height: 122.22%;
    display: block;
    font-weight: 700;
    @include m(479) {
      font-size: 28px;
    }
    &-block {
      display: flex;
      margin-top: 23px;
      align-items: center;
    }
    &--old {
      margin-left: 15px;
      color: #418FDE;
      &.not-active {
        color: #000;
        text-decoration: line-through;
      }
    }
    &--current {
      margin-left: 15px;
      color: #418FDE;
    }
    &_decrypt {
      display: block;
      font-size: 11px;
      color: #c9c9c9;
    }
  }
  &_btn {
    margin-right: 20px;
    max-width: 360px;
    @include m(959) {
      max-width: 345px;
      margin-right: 0;
      margin-bottom: 20px;
    }
    @include m(767) {
      margin-bottom: 12px;
    }
  }
  &_actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    @include m(959) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &_backimg1 {
    position: absolute;
    max-height: 110%;
  }
  &_backimg2 {
    position: absolute;
    max-height: 110%;
    @include m(390) {
      display: none;
    }
  }
  &_banner_{
    &btn {
      background: white;
      color: #4290DF;
      font-weight: 700;
      &:hover {
        background: #f4f4f4;
      }
    }
    &content {
      flex: 1;
      padding: 36px;
      border-radius: 19px;
      background: linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      z-index: 1;
      position: relative;

      @include m(959) {
        padding: 28px;
      }

      @include m(459) {
        padding: 22px;
      }
    }
    &img {
      width: 100-54.44+0%;
      display: flex;
      position: relative;
      padding: 10px;

      align-items: center;
      justify-content: center;

      @include m(767) {
        display: none;
      }
      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;

      }
    }
    &text {
      color: white;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.12;

      @include m(767) {
        font-size: 14px;
      }
    }
  }
  &_price {
    font-weight: 400;
    font-size: 40px;
    line-height: 1.08;

    @include m(767) {
      font-size: 24px;
    }
  }
  &_smth {
    display: flex;
    &_mark {
      flex: 0 0 24px;
      background-color: white;
      border-radius: 2px;
      display: flex;
      div {
        color: #4290DF;
        display: block;
        margin: auto 0px;
        width: 100%;
        text-align: center;
        line-height: 1.34;
        font-size: 32px;
        font-weight: 700;

        @include m(767) {
          font-size: 26px;
        }
      }
    }
  }
}

.noobo {
  .lead_price {
    visibility: hidden;
  }
}

.leadn {
  &_list {
    display: flex;
    @include m(959) {
      flex-wrap: wrap;
    }
  }
  &_col {
    flex: 1 0 50%;
    padding-right: 27px;

    @include m(959) {
      flex: 1 1 100%;
    }
  }
  &_date {
    font-weight: 700;
    font-size: 24px;

    @include m(959) {
      font-size: 18px;
    }
  }
  &_or {
    font-weight: 700;
    font-size: 18px;
    line-height: 2.39;

    @include m(959) {
      font-size: 16px;
    }
  }
  &_header {
    margin-bottom: 24px;
    @include m(960) {
      margin-bottom: 16px;
    }
  }
}
