.teacher {
  padding: 60px 0px;
  @include m(767) {
    padding: 30px 0px;
  }
  &_cols {
    display: flex;
    width: 100%;
    @include m(959) {
      flex-wrap: wrap;
    }
  }
  &_heading {
    @include m(959) {
      display: none;
    }
    &-mob {
      display: none;
      @include m(959) {
        display: block;
      }
    }
  }
  &_col {
    height: auto;
    flex: 1 1 50%;
    max-width: 50%;
    @include m(959) {
      flex: 1 1 100%;
      max-width: 100%;
    }

    &_text {
      padding-bottom: 80px;
      @include m(959) {
        padding-bottom: 0px;
      }
    }
  }
  &_img_wrapper {
    padding-right: 56px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include m(959) {
      min-height: 300px;
      max-height: 300px;
      padding-right: 0px;
    }

    img {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      max-height: 130%;
      @include m(959) {
        max-height: 110%;
      }
    }

    .bigga {
      max-height: 140%;
      @include m(959) {
        max-height: 120%;
      }
    }
  }
  &_name {
    display: block;
    margin-top: 30px;
  }
  &_img {
    display: block;
    @include m(959) {
      display: none;
    }
    &-mob {
      &-wrapper {
        position: relative;
      }
      &_description {
        display: none;
        position: absolute;
        width: 100%;
        max-width: 330px;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        background: rgba(0, 0, 0, 0.4);
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
        border-radius: 21px;
        padding: 18px 22px 30px;
        will-change: backdrop-filter;
        @include m(959) {
          display: block;
        }
        &_name {
          display: block;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #FFFFFF;
        }
        &_content {
          display: block;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
        }
      }
    }
    &--mob {
      display: none;
      @include m(959) {
        display: block;
        width: 100%;
        height: auto;
        max-width: 70%;
        min-width: 240px;
        margin: 20px auto 0;
      }
      @include m(479) {
        max-width: calc(100% - 40px);
      }
    }
  }
  &_controls {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 26px;
    display: flex;
    align-items: center;
    width: 210px;
    background-color: transparent;
    @include m(959) {
      bottom: auto;
      top: calc(var(--img-height) / 1.40);
      transform: translateY(-50%);
      width: 100%;
      right: 0px;
      left: 0px;
    }
    &_uknw {
      left: 50%;

      @include m(959) {
        width: 100%;
        left: 0px;
        top: 220px;
        padding: 0px 5px;
      }
    }

    &_nav {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 32px;
      max-width: 32px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      cursor: pointer;
      border: none;
      margin: 0;
      padding: 0;
      background: #F4F4F4;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.17);
      position: relative;
      &_icon {
        &_circle {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          width: 32px;
          height: 32px;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
    &_slider {
      flex: 1 1 120px;
      max-width: 120px;
      margin: 0 16px;
    }
    &_title {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 13px;
      justify-content: center;
      width: 100%;
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  .swiper-wrapper {
    .swiper-slide {
      height: auto;
      display: flex;
      @include m(959) {
        opacity: 0;
      }
    }
  }
}

.teacher_user {
  padding-top: 0px;
  @include m(767) {
    padding-top: 0px;
  }

  .container {
    padding: 60px 0px;
    @include m(767) {
      padding: 30px 0px;
    }
  }

  .swiper-wrapper .swiper-slide {
    opacity: 1;
  }
}
