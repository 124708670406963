.footer {
  padding: 70px 0 54px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  @include m(1199) {
    padding: 20px 0 30px;
  }
  @include m(479) {
    height: 300px;
    padding: 30px 0 0;
  }
  &_content {
    display: flex;
    margin-top: 25px;
  }
  &_copyright {
    display: block;
    color: #7A7A7A;
    font-size: 13px;
    line-height: 130.77%;
    a {
      color: #7a7a7a;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    @include m(479) {
      width: 100%;
      text-align: center;
    }
  }
  &_delimiter {
    @include m(479) {
      margin-top: 12px;
    }
  }
  &_social {
    margin-left: auto;
    display: flex;
    align-items: center;
    @include m(479) {
      display: none;
    }
    &-mob {
      display: none;
      @include m(479) {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    &_lnk {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      background-color: #418FDE;
      border-radius: 17px;
      &:not(:first-of-type) {
        margin-left: 48px;
        @include m(1200) {
          margin-left: 25px;
        }
      }
      &_icon {
        display: block;
        width: 100%;
        max-width: 20px;
        height: auto;
        max-height: 20px;
      }
    }
  }
}
