.ap_item {
  visibility: hidden;
  @include m(959) {
    visibility: visible;
  }
}

.tas {
  visibility: hidden;
  @include m(959) {
    visibility: visible;
  }
}

.appearence{

  .ap_item {
    animation-name: appearance;
    animation-duration: 400ms;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    @include m(959) {
      animation-name: none;
    }
  }

  &_self {
    animation-name: appearance;
    animation-duration: 400ms;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    @include m(959) {
      animation-name: none;
    }
  }
}

@keyframes appearance {
  from {
    visibility: visible;
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}
