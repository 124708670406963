.ahead {
  background-color: #F4F4F4;
  padding: 60px 0 44px;
  position: relative;
  margin-top: 45px;
  min-height: 520px;

  @include m(959) {
    padding-bottom: 0;
    margin-top: 30px;
    min-height: auto;
  }
  @include m(767) {
    padding: 30px 0 0;
    margin-top: 20px;
  }
  &_mob-wrapper {
    @include m(959) {
      max-width: 360px;
      margin: 0 auto;
    }
  }
  .container {
    @include m(959) {
      padding-bottom: 0px;
    }
  }
  &_mobile-bg {
    display: none;
    @include m(959) {
      margin-bottom: -90px;
      display: block;
      max-width: 130%;
      height: auto;
      width: 130%;
      margin-top: 20px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_bg {
    position: absolute;
    right: -185px;
    top: -70px;
    bottom: -104px;
    z-index: 1;
    transform-origin: 50% 50%;
    width: 759px;
    @include m(959) {
      display: none;
    }
    @include m(1229) {
      right: -120px;
      width: 68%;
    }
    &_img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 110%;
      height: auto;
      display: block;
      z-index: 1;
      transform-origin: 51% 49%;
      transform: translate(-50%, 0);
      @include m(1169) {
        // top: 45%;
        // left: 60%;
      }
    }
    &_wrapper {
      position: absolute;
      top: -70px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform-origin: 49% 49%;
    }
  }
  &_subtitle {
    margin-top: 8px;

    @include m(767) {
      margin-top: 4px;
    }
  }
  &_description {
    display: block;
    margin-top: 40px;
    font-size: 15px;
    line-height: 126.67%;
    max-width: 500px;
    br {
      display: inline;
    }
    @include m(959) {
      margin-top: 40px;
      width: auto;
      font-size: 14px;
    }
    @include m(767) {
      margin-top: 40px;
    }
  }
  &_lnk {
    display: block;
    margin-top: 15px;
    color: #418FDE;
    font-size: 15px;
    line-height: 126.67%;
    font-weight: 700;
  }
  &_bid {
    display: block;
    margin-right: 20px;
    @include m(959) {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
    @include m(767) {
      margin-bottom: 12px;
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    margin-top: 115px;
    @include m(959) {
      margin-top: 0;
      flex-direction: column;
      bottom: 30px;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 2;
    }
  }
  &_btn-holder {
    margin-top: 26px;

    @include m(959) {
      margin: 0px auto;
      position: relative;
      top: -60px;
      max-width: 360px;
    }
  }
  &_panel {
    width: 235px;
    margin-top: 18px;

    @include m(959) {
      width: 100%;
    }

    span {
      width: inherit;
      text-align: center;
      display: block;
    }

    &_1 {
      width: 100%;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 11px;

      span {
        font-weight: 700;
        font-size: 16px;

        line-height: 1.19;
      }
    }
    &_2 {
      flex-grow: 1;
      padding-bottom: 13px;
      padding-top: 13px;
      margin-right: 11px;
      float: left;

      @include m(959) {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
    &_3 {
      flex-grow: 1;
      padding-bottom: 13px;
      padding-top: 13px;
      float: right;
      @include m(959) {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }

    &_t1 {
      font-weight: 400;
      font-size: 12px;
      color: #6A6A6A;
      line-height: 1.22;
      margin-bottom: 2px;
      @include m(959) {
        font-weight: 400;
        font-size: 10px;
        line-height: calc(12 / 10);
      }
    }
    &_t2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 1.24;
      margin-bottom: 2px;
      @include m(959) {
        font-size: 20px;
        line-height: calc(24 / 20);
        margin-bottom: 0px;
      }
    }
    &_t3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.19;

      @include m(959) {
        font-size: 14px;
        line-height: calc(17 / 14)
      }
    }
  }
}
