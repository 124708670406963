.popup {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  &_overlay {
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &_body {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    width: 100%;
    max-width: 1104px;
    max-height: 85vmin;
    background-image: url('~/src/assets/img/popup-bg.png');
    background-repeat: no-repeat;
    background-position: 108% 5px;
    background-size: auto 110%;
    overflow-y: auto;
    #thx & {
      max-width: 767px;
    }
    @include m(1169) {
      max-width: 767px;
      max-height: 424px;
      overflow: auto;
      background-position: 112% 5px;
      background-size: auto 105%;
    }
    // @include m(1199) {
    //   padding: 40px 60px 30px;
    //   max-width: 720px;
    // }
    @include m(767) {
      padding: 30px 20px;
      background-image: none;
      left: 50%;
      max-width: 360px;
      transform: translate(-50%, -50%);
    }
  }
  &_content {
    width: 100%;
    padding: 40px 107px 56px;
    overflow-y: auto;
    @include m(1169) {
      padding: 40px 56px 60px;
    }
    @include m(767) {
      padding: 0;
    }
  }
  &_close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #F4F4F4;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.17);
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $trs;
    @include m(479) {
      width: 24px;
      height: 24px;
      top: 10px;
      right: 10px;
      border-radius: 12px;
    }
    &:hover {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.34);
    }
    &_icon {
      @include m(479) {
        display: block;
        width: 7px;
        height: 7px;
      }
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    max-width: 535px;
    margin-top: 20px;
    @include m(1169) {
      max-width: 456px;
    }
    @include cl;
    @include m(767) {
      margin-top: 15px;
    }
  }
  &_input {
    display: block;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    font-size: 15px;
    // &.phoneinput:hover + .popup_input-label {
    //   font-size: 10px;
    //   top: 5px;
    //   transform: translateY(0);
    // }
    &:focus + .popup_input-label, &:not([data-value=""]) + .popup_input-label {
      font-size: 10px;
      top: 3px;
      transform: translateY(0);
    }
    &-container {
      background-color: #F4F4F4;
      border-radius: 2px;
      width: 100%;
      height: 56px;
      position: relative;
      transition: $trs;
      @include m(1169) {
        height: 42px;
      }
      &:not(:first-of-type) {
        margin-top: 30px;
      }
      &::after {
        content: attr(data-errorText);
        display: block;
        position: absolute;
        z-index: -1;
        transform: translateY(-30px);
        left: 0;
        top: 100%;
        margin-top: 2px;
        font-size: 13px;
        line-height: 130.77%;
        color: #595959;
        transition: $trs3;
        @include m(767) {
          font-size: 12px;
          margin-top: 0;
        }
      }
      &--invalid {
        background-color: #f9e9e9;
        &::after {
          transform: translateY(0);
        }
      }
    }
    &-label {
      font-size: 15px;
      line-height: 126.67%;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      transition: $trs;
      @include m(767) {
        font-size: 14px;
      }
    }
  }
  &_submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;
    max-width: 235px;
    @include m(1169) {
      height: 42px;
      margin-top: 40px;
    }
    @include m(767) {
      margin-top: 40px;
      width: 100%;
      max-width: 345px;
    }
    @include m(479) {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      margin-top: 30px;
    }
  }
  &_actions {
    margin-top: 56px;
    display: flex;
    align-items: center;
    @include m(1169) {
      margin-top: 40px;
    }
    @include m(767) {
      flex-direction: column;
      width: 100%;
    }
  }
  &_action-btn {
    margin-right: 40px;
    height: 56px;
    width: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: $trs;
    text-decoration: none;
    @include m(1169) {
      height: 42px;
    }
    @include m(767) {
      margin-right: 0;
      margin-bottom: 30px;
      width: 100%;
      max-width: 345px;
    }
    @include m(479) {
      margin-bottom: 20px;
      width: 240px;
    }
    &:hover {
      background: linear-gradient(282.87deg, lighten(#418FDE, 10%) -18.54%, darken(#418FDE, 10%) 49.1%, lighten(#9BCBEB, 10%) 152.35%);
    }
    &_icon {
      display: block;
      margin-left: 15px;
    }
    &_content {
      display: block;
      font-size: 16px;
      color: #fff;
    }
  }
  p {
    max-width: 560px;
  }
  h2 {
    display: block;
    @include m(1169) {
      font-size: 25px;
      line-height: 1.24;
      br {
        display: none;
      }
    }
    @include m(767) {
      br {
        display: block;
      }
    }
  }
  &-caller {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #418FDE;
    border-radius: 8px;
    height: 56px;
    width: 235px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #418FDE;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    margin: 0;
    @include m(959) {
      font-size: 14px;
      width: 100%;
      max-width: 345px;
      height: 44px;
      br {
        display: none;
      }
    }
  }
}
.blur-wrapper {
  transition: $trs3;
  padding-top: 65px;
  position: relative;
  &--active {
    filter: blur(3px);
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
  }
  @include m(767) {
    padding-top: 40px;
  }
}
