.about {
  padding: 60px 0 70px;
  @include m(959) {
    padding: 30px 0;
  }

  &_cols {
    display: flex;
  }
  &_col {
    padding: 0 15px;
    @include m(767) {
      padding: 0;
    }
    &--left {
      flex: 1 1 66.6666%;
      max-width: 66.6666%;
      @include m(767) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    &--right {
      flex: 1 1 33.3333%;
      max-width: 33.3333%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include m(767) {
        display: none;
      }
    }
  }
  &_content {
    display: block;
    margin-top: 25px;
    font-size: 15px;
    line-height: 126.67%;
    @include m(959) {
      font-size: 14px;
      margin-top: 15px;
    }
    @include m(767) {
      margin-top: 10px;
    }
    &-block {
      @include cl;
      h4 {
        margin-top: 50px;
        @include m(959) {
          margin-top: 30px;
        }
      }
    }
  }
  &_partners {
    margin: 15px -1% 0;
    display: flex;
    align-items: center;
    @include m(450) {
      flex-wrap: wrap;
    }
  }
  &_partner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 23%;
    max-width: 23%;
    width: 23%;
    margin: 0 1%;
    max-height: 76px;
    transition: $trs;
    filter: saturate(0) grayscale(1) opacity(0.5);
    &:hover {
      filter: none
    }
    @include m(767) {
      flex: 1 1 20%;
      max-width: 20%;
      width: 20%;
      max-height: 30px;
      margin: 0 2.5%;
    }
    @include m(450) {
      margin-bottom: 20px;
      flex: 1 1 40%;
      max-width: 40%;
      width: 40%;
    }
    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 76px;
      @include m(767) {
        max-height: 30px;
      }
    }
  }
  &_logo {
    display: block;
    height: auto;
    @include m(959) {
      max-width: 170px;
    }
    @include m(767) {
      display: none;
    }
    &-mob {
      display: none;
      @include m(767) {
        display: block;
        width: 100%;
        max-width: 55vmin;
        margin: 17px auto 7px;
      }
    }
  }
}
