.asdf {
  padding: 60px 0px;

  @include m(959) {
    padding: 30px 0px;
  }

  &_warning {
    margin: 0px auto;
    padding: 12px 40px;
    border: 1px solid #418FDE;
    border-radius: 12px;

    @include m(959) {
      padding: 12px 21px;
    }
  }

  &_banner{
    position: relative;
    background: linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    display: flex;
    @include m(549) {
      flex-direction: column-reverse;
      margin-top: 20px;
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      @include m(549) {
        flex-direction: column-reverse;
      }
    }
    &_content {
      padding: 40px;
      width: 100-54.44+0%;
      position: relative;
      z-index: 1;
      @include m(767) {
        padding: 25px 20px;
        width: 54.44%;
      }
      @include m(549) {
        width: 100%;
      }
    }
    &_img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 220px;
      @include m(767) {
        max-height: 150px;
      }
      &-block {
        flex: 1;
        background: #FDFDFD;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_title {
      color: #ffffff;
    }
    &_description {
      display: block;
      margin-top: 15px;
      color: #ffffff;
      font-size: 15px;
      line-height: 126.67%;
    }
    &_list {
      @extend .advantages_item_description;

      list-style: square;
      padding-left: 15px;

      li {
        padding-bottom: 8px;
      }
    }
  }

  &_item {
    border-bottom-color: #CDCDCD;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  &_ahead {
    padding: 12px 0px;
    position: relative;
    padding-right: 40px;
    cursor: pointer;
  }
  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: calc(26 / 20);
    cursor: pointer;

    @include m(959) {
      font-size: 14px;
    }
  }
  &_switch {
    user-select: none;
    position: absolute;
    right: 0px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.17);
    background: #F4F4F4;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    cursor: pointer;

    @include m(959) {
      width: 17px;
      height: 17px;
    }

    &_part {
      max-width: 50%;
      flex: 1 0 50%;
      position: relative;
      overflow: hidden;
      height: 100%;

      &__left, &__right {
        @extend .asdf_switch_part;
      }
    }

    &_stick {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #A4A4A4;
      top: calc(50% + 3px);
      transition-duration: 200ms;
      transition-property: top, transform;

      @include m(959) {
        height: 1px;
        top: calc(50% + 2px)
      }

      &__left {
        @extend .asdf_switch_stick;
        right: -50%;
        transform: rotate(45deg);
      }

      &__right {
        @extend .asdf_switch_stick;
        left: -50%;
        transform: rotate(-45deg)
      }
    }

    &__active {
      .asdf_switch_stick {
        background-color: #418FDE;
        top: calc(50% - 4px);

        @include m(959) {
          top: calc(50% - 1px)
        }

        &__left {
          transform: rotate(-45deg);
        }
        &__right {
          transform: rotate(45deg);
        }
      }
    }
  }
  &_body {
    overflow: hidden;
    position: relative;
    transition: max-height linear 2s;
  }

  &_content {
    border-top-color: #4290DF;
    border-top-width: 4px;
    border-top-style: solid;
    padding: 24px 0px;
    font-weight: 400;
    font-size: 15px;
    line-height: 2;
    color: #000000;
      p {
        max-width: 600px;
      }
    @include m(959) {
      font-size: 14px;
      line-height: 1.25;
    }
  }

}
