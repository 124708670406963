.profit {
  padding: 60px 0;
  background-color: #F4F4F4;
  @include m(959) {
    padding: 30px 0;
  }
  &_cols {
    display: flex;
    margin-top: 5px;
    @include m(959) {
      flex-wrap: wrap;
    }
  }
  &_col {
    flex: 1 1 auto;
    @include m(959) {
      flex: 1 1 100%;
      max-width: 100%;
      order: 1;
    }
    &_img {
      flex-basis: 60%;
      @include m(959) {
        flex-basis: 100%;
        margin-bottom: 20px;
        min-height: 232px;
        order: 0;
      }
    }
  }
  &_list {
    display: block;
    &_item {
      display: flex;
      @include m(959) {
        margin-top: 10px;
      }
      &_icon {
        display: block;
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 15px;
      }
      &_content {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
  &_tiles {
    @include cl;
    padding: 24px 88px;
    width: 100%;
    height: 100%;

    @include m(959) {
      padding: 20px;
    }
  }
  &_img_wrapper {
    width: 100%;
    height: 100%;
    border-radius: 21px;
    position: relative;

    @include m(959) {
      max-width: 450px;
      margin: 0px auto;
    }

    img {
      position: absolute;
      height: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &_tile {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 18px 60px;
    min-height: 75px;
    @include m(959) {
      padding: 10px 15px;
      border-radius: 8px;
    }
    &:not(:first-of-type) {
      margin-top: 20px;
      @include m(959) {
        margin-top: 10px;
      }
    }
    &_icon {
      display: block;
      width: 100%;
      height: auto;
      max-width: 21px;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        min-width: 36px;
        margin-right: 30px;
        background-color: #418FDE;
        border-radius: 18px;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        @include m(959) {
          margin-right: 10px;
        }
      }
    }
    &_content {
      display: block;
      font-size: 15px;
      line-height: 126.67%;
    }
  }
}
