.plan {
  padding: 60px 0 0;
  background-color: #F4F4F4;
  @include m(767) {
    padding: 30px 0 0;
  }

  .container {
    padding-bottom: 60px;
    @include m(767) {
      padding-bottom: 30px;
    }
  }
  &_pre-slider {
    margin-top: 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CDCDCD;

    &_navigation-btn {
      flex: 1 1 32px;
      max-width: 32px;
      min-width: 32px;
      border-radius: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      background: #F4F4F4;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.17);
      &[disabled] {
        cursor: not-allowed;
      }
      &:not([disabled]) {
        cursor: pointer;
      }
      &_icon {
        display: block;
        width: 10px;
        flex: 1 1 10px;
        max-width: 10px;
        &--right {
          transform: rotate(180deg);
        }
      }
    }
    .swiper-container {
      margin: 0 27px;
    }
    &_slide {
      font-size: 13px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      padding: 14px 0;
      border-bottom: 2px solid transparent;
      user-select: none;
      &--active {
        border-bottom-color: #418FDE;
      }
    }
  }
  &_slider {
    margin: 25px -12px 0;
    &_item {
      background: #F4F4F4;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      display: flex;
      margin: 12px;
      @include m(659) {
        flex-direction: column;
        padding-bottom: 150px;
        position: relative;
      }
      &_col {
        &:first-of-type {
          flex: 1 1 39.71%;
          max-width: 39.71%;
          padding: 40px;
          display: flex;
          flex-direction: column;
          @include m(959) {
            padding: 20px 25px;
            flex: 1 1 50%;
            max-width: 50%;
          }
          @include m(659) {
            flex: 1 1 100%;
            max-width: 100%;
            padding: 25px 25px 10px;
          }
          @include m(479) {
            padding: 20px 15px 10px;
          }
        }
        &:last-of-type {
          flex: 1 1 100-39.71+0%;
          max-width: 100-39.71+0%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FDFDFD;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
          border-radius: 20px;
          padding: 30px;
          @include m(959) {
            flex: 1 1 50%;
            max-width: 50%;
          }
          @include m(659) {
            flex: 1 1 100%;
            max-width: 100%;
            max-height: 200px;
            justify-content: flex-start;
            padding: 0 25px;
            background-color: transparent;
            box-shadow: none;
          }
          @include m(479) {
            padding: 0 15px;
            max-height: none;
          }
        }
      }
      &_img {
        display: block;
        max-width: 340px;
        width: 100%;
        height: auto;
        @include m(659) {
          height: 100%;
          width: auto;
          max-height: 200px;
          margin: 0 auto;
        }
        @include m(479) {
          max-height: none;
          width: 100%;
          max-width: none;
        }
      }
      &_description {
        display: block;
        margin-top: 25px;
        font-size: 15px;
        line-height: 126.67%;
      }
      &_lnk {
        display: block;
        margin-top: 25px;
        font-weight: 700;
        font-size: 15px;
        line-height: 126.67%;
        color: #418FDE;
      }
      &_lead {
        margin-top: auto;
        margin-bottom: 10px;
        max-width: 235px;

        @include m(959) {
          max-width: 345px;
        }
        @include m(767) {
          margin-bottom: 12px;
        }
        @include m(659) {
          position: absolute;
          max-width: 345px;
          bottom: 55px;
          left: 50%;
          transform: translateX(-50%);
          & + .popup-caller {
            position: absolute;
            bottom: 10px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        @include m(419) {
          max-width: calc(100% - 30px);
          & + .popup-caller {
            max-width: calc(100% - 30px);
          }
        }
      }
    }
  }

  &_card {
    border-radius: 20px;
    margin: 9px 10px;
    flex: 1 1 40%;
    padding: 24px 30px;
    display: flex;

    @include m(759) {
      min-width: 355px;
      padding: 20px 24px;
    }

    @include m(400) {
      min-width: 220px;
    }

    &__empty {
      @include m(759) {
        display: none;
      }
    }

    &_icon {
      margin-top: auto;
      margin-bottom: auto;
      flex: 0 0 36px;
      max-width: 36px;
      max-height: 36px;
      height: 36px;
      background-color: #418FDE;
      border-radius: 100%;

      span {
        width: 100%;
        margin: auto 0px;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 2.39;
        display: block;
        text-align: center;
        transform: translateY(-4px);
      }

      @include m(959) {
        flex: 0 0 28px;
        max-width: 28px;
        max-height: 28px;
        height: 28px;

        span {
          font-size: 16px;
        }
      }
    }

    &_info {
      margin-left: 17px;
      margin-right: 40px;
      flex: 1 1 auto;
      margin-top: auto;
      margin-bottom: auto;

      @include m(759) {
        margin-right: 0px;
      }
      p {
        margin: 0px;
        padding: 0px;
      }
    }

  }
}
