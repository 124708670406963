.header {
  background-color: #ffffff;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  @include m(767) {
    height: 40px;
  }
  .container {
    padding: 13px 0;
    border-bottom: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
    height: 100%;
    @include m(767) {
      border-bottom: none;
      height: 40px;
    }
  }
  &_logo {
    display: block;
    width: 167px;
    height: 39px;
    flex: 1 1 167px;
    max-width: 167px;
    cursor: pointer;
    @include m(959) {
      display: none;
    }
    &-mob {
      display: none;
      @include m(959) {
        display: block;
        position: relative;
        z-index: 9;
        width: 34px;
        height: 17px;
        flex: 1 1 34px;
        max-width: 34px;
      }
      &_icon {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
  &_toggler {
    position: relative;
    z-index: 9;
    height: 14px;
    width: 20px;
    background-color: transparent;
    border: none;
    margin-right: 25px;
    padding: 0;
    display: none;
    @include m(959) {
      display: block;
    }
    &--active {
      border-top: 2px solid transparent;
      body & i {
        background-color: transparent;
        &::before {
          transform: rotate(-45deg);
          top: 0;
        }
        &::after {
          transform: rotate(45deg);
          bottom: 0;
        }
      }
    }
    i {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 2px;
      transform: translateY(-50%);
      background-color: #aaa6a6;
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        left: 0;
        right: 0;
        top: 6px;
        background-color: #aaa6a6;
        transition: $trs3;
      }
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        left: 0;
        right: 0;
        bottom: 6px;
        background-color: #aaa6a6;
        transition: $trs3;
      }
    }
  }
  &_menu {
    display: none;
    @include m(959) {
      position: fixed;
      z-index: 8;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 360px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 0 0 15px rgba(#000, 0.5);
      transform: translateX(-150%);
      transition: $trs3;
    }
    &--active {
      transform: translateX(0);
    }
    &_list {
      display: block;
    }
    &_item {
      display: block;
      margin-top: 15px;
    }
    &_link {
      padding: 0 15px;
      color: #000;
      text-decoration: none;
      font-size: 16px;
    }
  }
  &_nav {
    display: block;
    @include m(959) {
      display: none;
    }
    &_list {
      display: flex;
      align-items: center;
    }
    &_item {
      display: block;
      margin-left: 50px;
      @include m(1399) {
        margin-left: 30px;
      }
    }
    &_link {
      display: block;
      cursor: pointer;
      color: #000;
      font-size: 13px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        margin-top: 23px;
        left: 0;
        right: 0;
        height: 3px;
        background-color: #418FDE;
        opacity: 0;
        transition: $trs;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &_bid {
    display: block;
    margin-left: auto;
    background-color: transparent;
    border: none;
    color: #418fde;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    @include m(959) {
      font-weight: 400;
      font-size: 11px;
    }
  }
}

.noobo {
  .header_nav_item {
    margin-left: 30px;
    @include m(1399) {
      margin-left: 15px;
    }
  }
}
