.advantages {
  padding: 60px 0;
  background-color: #F4F4F4;

  @include m(767) {
    padding: 30px 0;
  }
  &_items {
    margin-top: 10px;
  }

  &_item {
    margin-top: 30px;
    position: relative;
    background: linear-gradient(265.77deg, #3988BB 25.25%, #4290DF 61.23%, #87ADCC 98.58%);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    display: flex;
    @include m(549) {
      flex-direction: column-reverse;
      margin-top: 20px;
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      @include m(549) {
        flex-direction: column-reverse;
      }
    }
    &_content {
      padding: 40px;
      width: 100-54.44+0%;
      position: relative;
      z-index: 1;
      @include m(767) {
        padding: 25px 20px;
        width: 54.44%;
      }
      @include m(549) {
        width: 100%;
      }
    }
    &_img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 220px;
      @include m(767) {
        max-height: 150px;
      }
      &-block {
        flex: 1;
        background: #FDFDFD;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_title {
      color: #ffffff;
    }
    &_description {
      display: block;
      margin-top: 15px;
      color: #ffffff;
      font-size: 15px;
      line-height: 126.67%;
    }
    &_list {
      @extend .advantages_item_description;

      list-style: square;
      padding-left: 15px;

      li {
        padding-bottom: 8px;
      }
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    margin-top: 40px;
    @include m(959) {
      margin-top: 30px;
    }
    @include m(767) {
      flex-direction: column;
      align-items: center;
    }
  }
  &_bid {
    display: block;
    margin-right: 20px;
    @include m(959) {
      width: 100%;
      max-width: 345px;
    }
    @include m(767) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

.grants {
  display: flex;
  @include m(959) {
    flex-wrap: wrap;
  }
  &_img-col {
    flex: 1 1 50%;
    max-width: 50%;
    padding-right: 56px;
    @include m(959) {
      flex: 1 1 100%;
      max-width: 100%;
      min-height: 232px;
      padding-right: 0px;
      margin-bottom: 25px;
    }
  }

  &_title {
    @include m(959) {
      display: none;
    }
  }

  &_mob-title {
    display: none;
    @include m(959) {
      display: block;
      max-width: 450px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &_text-col {
    flex: 1 1 50%;
    max-width: 50%;
    padding-right: 20px;

    @include m(959) {
      max-width: 450px;
      flex: 1 1 100%;
      width: 100%;
      margin: 0px auto;
    }
  }
}
