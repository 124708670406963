@font-face {
  font-family: 'Proxima Nova';
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-BoldIt.eot');
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-BoldIt.woff2') format('woff2'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-BoldIt.woff') format('woff'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-Bold.eot');
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-RegularIt.eot');
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-RegularIt.woff2') format('woff2'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-RegularIt.woff') format('woff'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-Regular.eot');
  src: url('~/src/assets/fonts/ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
  url('~/src/assets/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans";
  src: url("~/src/assets/fonts/PTSans/PTSans-RegularIt.eot");
  src: url("~/src/assets/fonts/PTSans/PTSans-RegularIt.eot?#iefix") format("embedded-opentype"),
  url("~/src/assets/fonts/PTSans/PTSans-RegularIt.woff2") format("woff2"),
  url("~/src/assets/fonts/PTSans/PTSans-RegularIt.woff") format("woff"),
  url("~/src/assets/fonts/PTSans/PTSans-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PT Sans";
  src: url("~/src/assets/fonts/PTSans/PTSans-Regular.eot");
  src: url("~/src/assets/fonts/PTSans/PTSans-Regular.eot?#iefix") format("embedded-opentype"),
  url("~/src/assets/fonts/PTSans/PTSans-Regular.woff2") format("woff2"),
  url("~/src/assets/fonts/PTSans/PTSans-Regular.woff") format("woff"),
  url("~/src/assets/fonts/PTSans/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
