.free {
  padding: 60px 0 0;
  @include m(767) {
    padding: 30px 0 0;
  }

  .container {
    padding-bottom: 30px;
  }

  .plyr__progress input[type="range"] {
    cursor: pointer;
  }

  &_pagination {
    margin-top: 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CDCDCD;
    justify-content: space-between;
    padding-bottom: 8px;

    &-item {
      flex: 1 1 auto;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      user-select: none;

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -8px;
        background-color: #418FDE;
        display: none;
      }

      &__active {
        &::after {
          display: block;
        }
      }

    }

  }
  &_slider {
    margin: 25px -12px 0;
  }

  &_slide {
    background: #F4F4F4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin: 12px;
    position: relative;

    display: flex;

    @include m(959) {
      padding-bottom: 130px;
      flex-wrap: wrap;
    }

    &-description {
      flex: 1 1 39.71%;
      max-width: 39.71%;
      padding: 20px 40px;


      @include m(959) {
        flex: 1 1 100%;
        max-width: 100%;
        padding: 20px;
      }

      @include m(479) {
        padding: 20px 15px;
      }
    }

    &-content {
      flex: 1 1 100-39.71+0%;
      max-width: 100-39.71+0%;
      position: relative;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      max-height: 396px;


      @include m(959) {
        flex: 1 1 100%;
        max-width: 100%;
        position: unset;
      }
    }

    &-video {
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      background: #FDFDFD;
      padding: 24px;
      width: 100%;
      max-width: 100%;
      height: 100%;

      video {
        max-width: 100%;
        max-height: 100%;
      }

      @include m(767) {
        padding: 16px;
      }

      @include m(419) {
        padding: 12px;
      }

      &__disabled {
        filter: blur(10px);
      }

    }

    &-about {
      display: none;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      position: absolute;
      background: #FDFDFD;
      top: 0px;
      overflow: hidden;
      padding: 48px 40px;
      padding-bottom: 0px;
      transform: translateX(-10%);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

      &__active {
        display: block;
      }

      @include m(959) {
        height: calc(100% - 130px);
        padding: 20px;
        padding-right: 36px;
        padding-top: 36px;
        transform: none;
      }
      @include m(419) {
        padding: 20px 15px;
        padding-right: 26px;
      }

      &_wrapper {
        padding-bottom: 60px;
        padding-right: 12px;
        overflow: auto;
        height: 100%;

        .simplebar-vertical {
          width: 8px;
          height: calc(100% - 60px);
        }
      }

      &_bottom {
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 60px;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 23.44%, #FFFFFF 100%);
        z-index: 1;
      }

      p {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #1C1C1C;
        margin: 8px 0px;
      }

      li {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #1C1C1C;
      }

      ul {
        list-style: square;
        margin-left: 15px;
      }

      a, a:visited, a:hover {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #418FDE;
        text-decoration: underline;
      }
    }

    &-buttons {
      position: absolute;
      bottom: 30px;
      max-width: 235px;
      width: 235px;

      @include m(959) {
        max-width: 345px;
        width: 345px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 18px;
      }
      @include m(419) {
        max-width: calc(100% - 30px);
        width: calc(100% - 30px);
        bottom: 12px;
      }
    }

    &-lead {
      margin-bottom: 10px;
    }

    &-text {
      display: block;
      margin-top: 25px;
      font-size: 15px;
      line-height: 126.67%;

      @include m(959) {
        margin-top: 15px;
      }

      @include m(659) {
        margin-top: 10px;
      }
    }

    &-lnk {
      display: block;
      margin-top: 8px;
      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 126.67%;
        color: #418FDE;
        cursor: pointer;
      }

      @include m(959) {
        margin-top: 8px;
      }
    }
  }

  &_about {
    &-close {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 2;
      cursor: pointer;
      width: 26px;
      height: 26px;
      background-color: #F4F4F4;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.17);
      border: none;
      border-radius: 16px;

      align-items: center;
      justify-content: center;
      transition: $trs;
      display: flex;

      @include m(959) {
        top: 10px;
        right: 10px;
      }

      @include m(479) {
        width: 20px;
        height: 20px;
        top: 8px;
        right: 8px;
        border-radius: 12px;
      }
      &:hover {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.34);
      }
      &_icon {
        @include m(479) {
          display: block;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
