@mixin cl () {
  &::before, &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin m ($viewport) {
  @media only screen and (max-width: $viewport + px) {
    @content
  }
}
