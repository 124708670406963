* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
}

body {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1;
  overflow-x: hidden;
  background: #FFFFFF;
  scroll-behavior: smooth;
  padding-bottom: 234px;

  @include m(959) {
    font-size: 14px;
    padding-bottom: 260px;
  }
  @include m(479) {
    padding-bottom: 300px;
  }
  &.fixed {
    touch-action: none
  }
}

h1, h2, h3, h4, h5 {
  display: block;
  font-weight: 700;
}

h1 {
  font-size: 44px;
  line-height: 109%;
  @include m(1199) {
    font-size: 40px;
  }
  @include m(959) {
    font-size: 22px;
  }
}

h2 {
  font-size: 36px;
  line-height: 122.22%;
  @include m(1199) {
    font-size: 28px;
  }
  @include m(959) {
    font-size: 22px;
  }
}

h4 {
  font-size: 24px;
  line-height: 120.83%;
  @include m(1199) {
    font-size: 18px;
  }
  @include m(959) {
    font-size: 16px;
  }
}

b {
  font-weight: 700;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  display: block;
  background-color: #CDCDCD;
  height: 1px;
}

.list {
  display: block;
  @include cl;
  &_item {
    display: flex;
    margin-top: 15px;
    position: relative;
    font-size: 15px;
    line-height: 126.67%;
    &::before {
      content: '';
      width: 9px;
      flex: 1 1 9px;
      max-width: 9px;
      min-width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #418FDE;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
      margin-top: 4px;
      margin-right: 15px;
    }
  }
}

.common-wrapper {
  position: relative;
  z-index: 2;
  background-color: #fff;
  overflow-x: hidden;
}

.btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
  min-width: 235px;
  padding: 0 15px;
  height: 56px;
  transition: $trs;
  border-radius: 8px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  @include m(959) {
    width: 100%;
    min-width: auto;
    max-width: 345px;
    max-height: 44px;
    font-size: 14px;
  }
  &:hover {
    background: linear-gradient(0deg, rgba(55, 118, 182, 0.54), rgba(55, 118, 182, 0.54)), linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
  }
  &:active {
    background: linear-gradient(0deg, #3776B6, #3776B6), linear-gradient(282.87deg, #418FDE -18.54%, #418FDE 49.1%, #9BCBEB 152.35%);
  }
}

.clearfix {
  @include cl
}

.container {
  width: calc(100% - 30px);
  max-width: 1104px;
  margin: 0 auto;
  position: relative;
  @include cl
}

.blur-wrapper {
  padding-top: 65px;
  position: relative;
  &--active {
    filter: blur(3px);
    overflow: hidden;
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
  }
  @include m(767) {
    padding-top: 40px;
  }
}

.noobo {
  &_item {
    display: none;
  }

  & .noobo_item {
    display: initial;
  }

  & .ne-noobo_item {
    display: none;
  }
}

