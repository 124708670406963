.why {
  padding: 60px 0;
  @include m(767) {
    padding: 30px 0;
  }
  &_cols {
    display: flex;
  }
  &_col {
    flex: 1 1 50%;
    max-width: 50%;
    height: auto;

    &_2 {
      max-width: 475px;
    }

    &:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      @include m(767) {
        display: none;
      }
      @include m(959) {
        flex: 1 1 40%;
        max-width: 40%;
      }
    }
    &:last-of-type {
      @include m(767) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
  &_img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 350px;
    @include m(969) {
      max-width: 85%;
      margin: 0 auto;
    }
    @include m(767) {
      display: none;
    }
    &--mob {
      display: none;
      @include m(767) {
        display: block;
        margin: 15px auto 0;
      }
      @include m(479) {
        max-width: 170px;
        margin: 15px auto 0;
      }
    }
  }
  &_title {
    margin-bottom: 25px;
    @include m(767) {
      margin-bottom: 15px;
    }
  }
  &_description {
    display: block;
    margin-top: 12px;
    font-size: 15px;
    line-height: 19px;
    @include m(767) {
      margin-top: 8px;
    }
  }
  &_actions {
    margin-top: 70px;
    display: flex;
    align-items: center;
    @include m(959) {
      margin-top: 40px;
      flex-direction: column;
      align-items: flex-start;
    }
    @include m(419) {
      align-items: center;
    }
  }
  &_bid {
    margin-right: 20px;
    @include m(959) {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 345px;
    }
    @include m(767) {
      margin-top: 20px;
      margin-bottom: 12px;
    }
  }
}
