.newlnk {
  &,
  &:active,
  &:focus,
  &:visited {
    display: inline-block;
    color: #418fde !important;
    word-break: unset;
    word-wrap: unset;
    overflow-wrap: unset;
    -webkit-hyphens: unset;
    -moz-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset;
  }
}
